<template>
  <el-dialog
    v-if="showAddCategory"
    :title="categoryForm.id ? '编辑' : '新增'"
    :visible="showAddCategory"
    width="504px"
    class="dialog-vertical"
    @close="closeAdd"
    close-on-click-modal
    close-on-press-escape
  >
    <el-form
      class="medium-form"
      :model="categoryForm"
      size="medium"
      label-width="100px"
      :rules="form_rules"
      ref="categoryForm"
      @submit.native.prevent
    >
      <el-form-item label="图标：" prop="icon">
        <!--            <single-media-wall v-model="configForm.icon" :width="60" :height="60" add-text="" />-->
        <el-popover
          v-model="isShowTemplateIcons"
          placement="bottom-start"
          title=""
          :visible-arrow="false"
          popper-class="template-icon"
          trigger="click"
        >
          <template #reference>
            <div class="icon-selector">
              <add-button
                v-if="!categoryForm.icon"
                :width="60"
                :height="60"
                text=""
                border-style="solid"
              />
              <options-image
                v-else
                class="cursor-pointer"
                :src="categoryForm.icon"
                :width="60"
                :height="60"
                hide-delete
                hide-preview
              />
            </div>
          </template>
          <div class="icon-grid">
            <div
              @click="chooseTemplateIcon(icon)"
              class="icon-grid-item cursor-pointer flex"
              v-for="icon in iconList"
              :key="icon"
            >
              <el-image class="flex-1" :src="icon"></el-image>
            </div>
            <!-- 如需要提供给用户自己上传的入口 -->
            <div class="icon-grid-item cursor-pointer no-hover">
              
              <single-media-wall
                :border-radius="4"
                :width="40"
                :height="40"
                add-text=""
                @handleMediaData="categoryForm.icon = $event"
              ><add-button text="" :width="40" :height="40" /></single-media-wall>
            </div>
          </div>
        </el-popover>
      </el-form-item>
      <!--      <el-form-item label="图标：" prop="icon">-->
      <!--        <el-input-->
      <!--          v-show="false"-->
      <!--          type="text"-->
      <!--          v-model="categoryForm.icon"-->
      <!--        ></el-input>-->
      <!--        <ImageItem v-model="categoryForm.icon" :width="100" :height="100" />-->
      <!--        <div class="info"><i class="el-icon-info"></i>建议200px*200px</div>-->
      <!--      </el-form-item>-->
      <el-form-item label="名称：" prop="name">
        <el-input
          v-model="categoryForm.name"
          placeholder="请填写"
          v-focus
        ></el-input>
      </el-form-item>
      <el-form-item label="显示：">
        <el-radio v-model="categoryForm.is_show" :label="1">显示</el-radio>
        <el-radio v-model="categoryForm.is_show" :label="0">不显示</el-radio>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="closeAdd">取消</el-button>
      <el-button
        size="small"
        type="primary"
        :loading="saveLoading"
        @click="handleAddCategory('categoryForm')"
        >确认</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import { getAllFacilityIcons, saveFacility } from '../../api'
import ImageItem from '../../../common/components/ImageItem'
import AddButton from '../../../../base/components/Base/AddButton'
import OptionsImage from '../../../../base/components/Media/OptionsImage'
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue'
export default {
  name: 'add-facility',
  components: { OptionsImage, AddButton, ImageItem,SingleMediaWall },
  props: ['showAddCategory', 'editObj'],
  data() {
    return {
      categoryForm: {
        is_show: 1,
        name: '',
        icon: '',
        id: 0,
      },
      form_rules: {
        name: [{ required: true, message: '请填写设施名称', trigger: 'blur' }],
      },
      saveLoading: false,

      iconList: [],
      isShowTemplateIcons: false,
    }
  },
  watch: {
    editObj() {
      if (this.editObj.name) {
        this.categoryForm = this.editObj
      }
    },
  },
  created() {
    getAllFacilityIcons()
      .then(({ data, msg }) => {
        this.iconList = data
      })
      .catch(() => {})
  },
  methods: {
    chooseTemplateIcon(obj) {
      this.categoryForm.icon = obj
      this.isShowTemplateIcons = false
    },
    handleAddCategory(configForm) {
      this.$refs[configForm].validate((valid) => {
        if (valid) {
          this.saveLoading = true
          saveFacility(this.categoryForm)
            .then((res) => {
              this.$emit('updateData')
              this.categoryForm = {
                is_show: 1,
                name: '',
                id: 0,
              }
              this.saveLoading = false
            })
            .catch((err) => {
              this.saveLoading = false
            })
        } else {
          return false
        }
      })
    },
    closeAdd() {
      this.categoryForm = {
        is_show: 1,
        name: '',
        icon: '',
        id: 0,
      }
      this.$refs.categoryForm.clearValidate()
      this.$emit('closeAddDialog')
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-grid {
  display: grid;
  grid-template-columns: repeat(5, 40px);
  grid-template-rows: repeat(auto-fit, 40px);
  grid-gap: 10px;

  .icon-grid-item {
    padding: 8px;

    &.no-hover {
      padding: 0;
    }

    &:not(.no-hover):hover {
      background-color: $borderLighter;
      border-radius: 4px;
    }
  }

  ::v-deep.el-image__error {
    background-color: unset;
    font-size: 10px;
    width: 100%;
    height: 100%;
  }
}
</style>

<style>
.el-popper.template-icon[x-placement^='bottom'] {
  margin-top: 0;
}
</style>
