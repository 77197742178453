<template>
  <div>
    <list-layout
            ref="listLayout"
            :filter-form="{}"
            :thead="thead"
            :on-fetch="getList"
            :on-toggle="toggleItem"
            :on-delete="deleteItem"
            @command="handleOperation"
    >
      <template #tabletop>
        <el-button type="primary" size="small" @click="showAddCategory = true"
        >添加</el-button
        >
        <el-button type="danger" size="small" @click="onDelete">删除</el-button>
      </template>
      <add-facility
              :showAddCategory="showAddCategory"
              :editObj="editObj"
              @closeAddDialog="closeAddDialog"
              @updateData="updateData"
      />
    </list-layout>
  </div>
</template>

<script>
import ListLayout from '@/base/layout/ListLayout'
import {
  removeFacility,
  getAllFacilities, saveFacility,
} from '../api'
import AddFacility from '../components/facility/AddFacility'

export default {
  components: { AddFacility, ListLayout },
  data() {
    return {
      thead: [
        {
          type: 'selection',
        },
        {
          label: 'ID',
          prop: 'id',
        },
        {
          label: '图标',
          prop: 'icon',
          type: 'icon'
        },
        {
          label: '名称',
          prop: 'name',
        },
        {
          label: '场地数量',
          prop: 'venues_count',
        },
        {
          label: '分类数量',
          prop: 'categories_count',
        },
        {
          label: '显示',
          prop: 'is_show',
          type: 'checkbox',
        },
        {
          label: '操作',
          type: 'operation',
          width: 150,
          operations: [
            { command: 'edit', text: '编辑' },
            { command: 'delete' },
          ],
        },
      ],
      showAddCategory: false,
      editObj: {},
    }
  },
  methods: {
    getList(q = {}) {
      return getAllFacilities(q)
    },
    // 刷新列表
    refreshList() {
      this.$refs.listLayout.getList()
    },
    deleteItem(data) {
      return removeFacility(data)
    },
    toggleItem(data) {
      const dataList = this.$refs.listLayout?.tbody;
      return saveFacility({ ...dataList.find(d => d.id === data.id), ...data })
    },
    updateData() {
      this.showAddCategory = false
      this.refreshList()
    },
    closeAddDialog() {
      this.showAddCategory = false
      this.editObj = {}
    },
    onDelete() {
      this.$refs.listLayout.deleteSelected()
    },
    handleOperation(e) {
      switch (e.command) {
        case 'edit': {
          this.editObj = e.row
          this.showAddCategory = true
          break
        }
        default:
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
